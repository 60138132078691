import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { NotFoundPage } from './pages/NotFound';
import { Story } from './pages/Story';

function App() {
	return (
		<React.Fragment>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/story/:id" element={<Story />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</React.Fragment>
	);
}

export default App;
