import { InboxIcon } from '@heroicons/react/24/outline';
import React from 'react';

import landingPageImg from '../../assets/images/landing-page.jpeg';
import logo from '../../assets/images/logo.svg';
import testimonialLeftImg from '../../assets/images/testimonial-left-img.svg';
import testimonialRightImg from '../../assets/images/testimonial-right-img.svg';
import whyChooseUsBG from '../../assets/images/why-choose-us-bg.svg';

const Header = () => (
	<div className="py-3 mx-6">
		<div className="container flex items-center justify-between">
			<div className="max-w-[150px] sm:max-w-auto block">
				<img src={logo} alt="logo" />
			</div>
		</div>
	</div>
);

const HeadingSection = () => (
	<div className="min-h-[calc(100vh-116px)] relative grid content-end pt-24 pb-5 md:py-20 lg:py-28">
		<div className="absolute top-0 left-0 w-full h-full before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-[#190D1F] before:opacity-[0.22]">
			<img
				src={landingPageImg}
				alt="hero img"
				className="w-full h-full object-cover"
			/>
		</div>
		<div className="mx-8 md:mx-12">
			<div className="relative ">
				<div className="container text-center justify-center items-center">
					<h1 className="text-4xl mb-6 md:mb-9 text-white md:text-6xl">
						Dream, plan, and document your trip effortlessly
					</h1>
					<p className="text-2xl mb-6 md:mb-9 text-white md:text-4xl">
						A single travel app catering to all your adventure needs
					</p>
				</div>
			</div>
		</div>
	</div>
);

const ProblemSection = () => {
	const statements = [
		'Do you strongly desire to document the travel experience, but it takes too much time and effort?',
		'Are you seeking invaluable travel experiences to be transformed into content for your friends and family, but there is no smart way to do so?',
		"Are you tired of juggling numerous tools to plan your trips, but there isn't a great tool in the market to streamline your experience?",
	];

	return (
		<div className="min-h-[calc(80vh)] mx-auto mt-20 relative">
			<div className="absolute -top-0 lg:-top-12 left-0 w-full">
				<img
					src={whyChooseUsBG}
					alt="why-choose-us-bg"
					className="w-full block"
				/>
			</div>
			<div className="mx-8 md:mx-12">
				<div className="container relative flex justify-between flex-wrap">
					<div className="w-full md:w-2/4">
						<h2 className="text-4xl">Are you facing these problems</h2>
					</div>
					<div className="w-full md:w-2/4 mt-10 md:mt-0">
						<ul className="ul-list mb-9">
							{statements.map((statement, index) => (
								<li
									key={index}
									className="mb-6 pb-6 md:mb-12 md:pb-12 border-b-2 last:border-b-0"
								>
									<p className="text-2xl text-slate-600">{statement}</p>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const WhyUsSection = () => (
	<div className="mx-8 md:mx-12">
		<div className="min-h-[calc(80vh)] relative container">
			<div className="flex flex-col text-left md:text-center mb-8 md:mb-14 ">
				<h2 className="text-4xl mb-10">How can we help you?</h2>
				<p className="text-2xl text-slate-600 max-w-[590px] mx-auto">
					ToRoamy is a central hub for effortless documenting and planning. Our
					AI copilot turns your photos into curated itineraries, travel blogs,
					and stunning collections, creating a valuable resource for the next
					trip planners!
				</p>
			</div>
			<div className="absolute bottom-0 left-0 hidden md:block">
				<img src={testimonialLeftImg} alt="testimonial-left-img" />
			</div>
			<div className="absolute bottom-0 right-0">
				<img src={testimonialRightImg} alt="testimonial-right-img" />
			</div>
		</div>
	</div>
);

const BodySection = () => (
	<>
		<ProblemSection />
		<WhyUsSection />
	</>
);
const FooterSection = () => (
	<div className="mx-8 md:mx-12">
		<section className="flex flex-wrap -mx-4 pt-10 justify-between">
			<div className="px-3 items-center text-left">
				<div className="mb-10 lg:mb-6 max-w-[260px]">
					<div className="mb-3 lg:mb-6">
						<img src={logo} alt="logo-dark" />
					</div>
					<p className="mb-5 font-normal text-15">
						Embarks on a new era of travel with ToRoamy — a central hub for
						effortless documenting and planning
					</p>
				</div>
			</div>
			<div className="px-3">
				<div className="mb-6">
					<h4 className="font-medium mb-3">Contact Us</h4>
					<ul className="">
						<li className="mb-3 flex">
							<InboxIcon className="h-6 w-6 text-blue-500 mr-3" />
							<p className="hover:text-primary-900">support@toroamy.com</p>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</div>
);

const Home = () => (
	<>
		<Header />
		<HeadingSection />
		<BodySection />
		<FooterSection />
	</>
);

export { Home };
