import axiosInstance from '..';

/**
 * Get Story by ID
 * @param storyId
 * @returns {getStory}
 */
export const getStory = async (storyId: string): Promise<any> => {
	const query = `
    query GetStory($storyId: String!) {
      getStory(storyId: $storyId) {
        images {
          url
        },
        itinerary {
          title,
          tripStops {
            images {
              url
            },
            details,
            startedAt,
            title
          }
        },
        summary,
        updatedAt,
      }
    }
  `;

	return await axiosInstance.post('', {
		query,
		variables: {
			storyId,
		},
	});
};
