import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../../assets/images/404.svg';

const NotFoundPage = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-900 px-6">
			<h1 className="text-2xl md:text-4xl font-bold text-center text-blue-600">
				404: Page Not Found
			</h1>
			<p className="text-md md:text-lg text-gray-700 text-center mt-2 mb-6">
				Oops! We can't seem to find the page you're looking for.
			</p>
			<img
				src={NotFoundImage}
				alt="404 not found"
				className="w-60 h-auto pb-32"
			/>
			<button
				className="px-6 py-3 bg-blue-600 text-white rounded-md shadow hover:bg-blue-800 w-full sm:w-48"
				onClick={() => navigate('/')}
			>
				Back to Home Page
			</button>
		</div>
	);
};

export { NotFoundPage };
