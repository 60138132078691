type SkeletonProps = {
	className?: string;
};

const Skeleton = ({ className }: SkeletonProps): JSX.Element => {
	const baseClasses = 'animate-pulse bg-gray-300 rounded';
	const combinedClasses = `${baseClasses} ${className}`;
	return <div className={combinedClasses} />;
};

export default Skeleton;
