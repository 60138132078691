import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useRef, useState, useEffect } from 'react';
import DefaultStoryImage from '../../../assets/images/default-story.jpeg';
import { Card } from './Card';

interface TripStop {
	images: Array<{ url: string }>;
	details: string;
	startedAt: string;
	title: string;
}

interface DayItineraryProps {
	day: number;
	itinerary: Array<TripStop>;
	isOpen: boolean;
	toggleDay: () => void;
}

const DayItinerary = ({
	day,
	itinerary,
	isOpen,
	toggleDay,
}: DayItineraryProps): JSX.Element => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const [maxHeight, setMaxHeight] = useState('0px');

	useEffect(() => {
		if (sectionRef.current) {
			setMaxHeight(`${sectionRef.current.scrollHeight}px`);
		}
	}, [isOpen, itinerary]);

	const rotationStyle = {
		transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
		transition: 'transform 0.3s ease',
	};

	const sectionStyle = {
		maxHeight: isOpen ? maxHeight : '0px',
		transition: 'max-height 0.5s ease-in-out',
		overflow: 'hidden',
	};

	return (
		<>
			<div
				className="flex gap-5 justify-between py-2 text-base font-semibold border-b border-solid border-zinc-100 text-zinc-900 cursor-pointer"
				onClick={toggleDay}
			>
				<div className="my-auto">Day {day}</div>
				<ChevronDownIcon className="w-6 h-6" style={rotationStyle} />
			</div>
			<section
				ref={sectionRef}
				className="box-border flex relative flex-col shrink-0 h-auto mb-2"
				style={sectionStyle}
			>
				{itinerary.map((item, index) => (
					<Card
						key={index}
						imageSrc={
							item.images.length > 0 ? item.images[0].url : DefaultStoryImage
						}
						title={item.title}
						time={item.startedAt}
					/>
				))}
			</section>
		</>
	);
};

export { DayItinerary };
