import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getStory } from '../../api/Story';
import book from '../../assets/images/book.svg';
import DefaultStoryImage from '../../assets/images/default-story.jpeg';
import map from '../../assets/images/map.svg';
import Skeleton from '../../components/Skeleton';

import { DayItinerary } from './components/DayItinerary';
import { ExpandableText } from './components/ExpandableText';
import { Image } from './components/Image';

interface TripStop {
	images: Array<{ url: string }>;
	details: string;
	startedAt: string;
	title: string;
}

function groupTripStopsByDate(tripStops: TripStop[]): TripStop[][] {
	const groups: Map<string, TripStop[]> = new Map();

	if (!tripStops) {
		return [];
	}

	tripStops.forEach((tripStop: TripStop) => {
		const tripStopDate: string = tripStop.startedAt.slice(0, 10); // YYYY-MM-DD 格式
		if (!groups.has(tripStopDate)) {
			groups.set(tripStopDate, []);
		}
		groups.get(tripStopDate)!.push(tripStop);
	});

	// 將 Map 轉換為 array of array of objects
	return Array.from(groups.values());
}

function formatDateRange(tripStops: TripStop[]): string {
	if (!tripStops) {
		return '';
	}
	if (tripStops.length === 0) {
		return '';
	}

	// 將所有日期解析為 Date 物件，並找出最小和最大的日期
	let minDate: Date = new Date(tripStops[0].startedAt);
	let maxDate: Date = new Date(tripStops[0].startedAt);

	tripStops.forEach((tripStop: TripStop) => {
		const currentDate: Date = new Date(tripStop.startedAt);
		if (currentDate < minDate) {
			minDate = currentDate;
		}
		if (currentDate > maxDate) {
			maxDate = currentDate;
		}
	});

	// 將日期格式化為 "MMM DD, YYYY"
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};
	const formattedMinDate: string = minDate.toLocaleDateString('en-US', options);
	const formattedMaxDate: string = maxDate.toLocaleDateString('en-US', options);

	return `${formattedMinDate} ~ ${formattedMaxDate}`;
}

const Story = (): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [story, setStory] = useState<any>({});
	const [expandedDay, setExpandedDay] = useState<number>(1);

	useEffect(() => {
		const fetchStory = async () => {
			if (!id) {
				throw new Response('', {
					status: 404,
					statusText: 'Not Found',
				});
			}
			try {
				const data = await getStory(id);
				if (!data.getStory) {
					throw new Response('', {
						status: 404,
						statusText: 'Not Found',
					});
				}
				setStory(data.getStory);
			} catch (error) {
				navigate('/404');
			}
		};

		fetchStory();
	}, []);

	const tripStops = groupTripStopsByDate(story.itinerary?.tripStops) || [];
	const dateRange = formatDateRange(story.itinerary?.tripStops);
	const toggleDay = (day: number) => {
		if (expandedDay === day) {
			setExpandedDay(0);
		} else {
			setExpandedDay(day);
		}
	};

	if (!story.itinerary) {
		return (
			<div className="flex flex-col pb-20 mx-auto w-full bg-white max-w-[480px]">
				<header className="relative w-full aspect-[1.64]">
					<Skeleton className="absolute inset-0 h-full" />
				</header>
				<div className="p-4">
					<div className="flex gap-2 mb-4 items-center">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="h-8 w-1/3 rounded" />
					</div>
					<Skeleton className="h-20 mt-4 mb-4 rounded-lg" />
					<div className="flex gap-2 mb-4 items-center">
						<Skeleton className="w-6 h-6 rounded-full" />
						<Skeleton className="h-8 w-1/3 rounded" />
					</div>
					<Skeleton className="h-20 mt-4 mb-4 rounded-lg" />
					<Skeleton className="h-40 mt-4 mb-4 rounded-lg" />
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col pb-20 mx-auto w-full bg-white max-w-[480px]">
			<header className="flex overflow-hidden relative flex-col justify-end w-full aspect-[1.64]">
				{story.images.length > 0 ? (
					<Image
						src={story.images[0].url}
						alt={story.itinerary.title}
						className="object-cover absolute inset-0 size-full"
					/>
				) : (
					<Image
						src={DefaultStoryImage}
						alt={story.itinerary.title}
						className="object-cover absolute inset-0 size-full"
					/>
				)}
				<div className="absolute bg-gradient-to-t from-black/60 to-black/0 w-full h-full flex items-end">
					<div className="flex relative flex-col p-4 text-zinc-50">
						<h1 className="text-2xl leading-7">{story.itinerary.title}</h1>
						<p className="mt-2 text-sm leading-5">{dateRange}</p>
					</div>
				</div>
			</header>
			<div className="p-4">
				<div className="flex gap-2">
					<Image src={book} alt="Book icon" className="w-6 aspect-square" />
					<h2 className="text-base font-semibold text-zinc-900">
						Trip Journal
					</h2>
				</div>
				<div className="self-center mt-4 text-base leading-5 text-zinc-900">
					<ExpandableText text={story.summary} />
				</div>
				<section className="flex flex-col items-start mt-8 w-full">
					<div className="flex gap-2">
						<Image src={map} alt="Map icon" className="w-6 aspect-square" />
						<h2 className="text-base font-semibold text-zinc-900">Itinerary</h2>
					</div>
					<Image
						src="https://cdn.builder.io/api/v1/image/assets/TEMP/ceb1e071147f1326bd9265fc7d623eba34ae76b1cc94d05a6f7dfff8d12693aa?apiKey=4b76db904b8f439caeb42009d263bf36&"
						alt="Map of trip location"
						className="self-stretch mt-4 w-full border-2 border-solid shadow-sm aspect-[2.7] border-zinc-100"
					/>
				</section>
				<section className="flex flex-col mt-6 w-full">
					{tripStops.map((dayItinerary, index) => (
						<DayItinerary
							key={index}
							day={index + 1}
							itinerary={dayItinerary}
							toggleDay={() => toggleDay(index + 1)}
							isOpen={expandedDay === index + 1}
						/>
					))}
				</section>
			</div>
		</div>
	);
};

export { Story };
