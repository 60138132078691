import axios from 'axios';

interface ApiResponse {
	error?: string;
	data?: any;
}

const service = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Accept-Language': navigator.language,
	},
});

// 攔截 response
service.interceptors.response.use(
	(response) => {
		const res: ApiResponse = response.data;

		if (res.error) {
			return Promise.reject(new Error(res.error));
		}

		return res.data;
	},
	(error) => {
		// cancel request
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		if (!error.response) {
			return Promise.reject(new Error('Network Error'));
		} else {
			const statusCode = error.response.status;
			switch (statusCode) {
				case 401:
					return Promise.reject(
						new Error('Unauthorized - Please log in again'),
					);
				case 403:
					return Promise.reject(
						new Error('Forbidden - You do not have permission'),
					);
				default:
					return Promise.reject(new Error('An unknown error occurred'));
			}
		}
	},
);

export default service;
