import { Image } from './Image';

interface CardProps {
	imageSrc: string;
	title: string;
	time: string;
}

function formatTime(isoString: string): string {
	const date: Date = new Date(isoString);
	return date.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});
}

const Card = ({ imageSrc, title, time }: CardProps): JSX.Element => (
	<div className="flex gap-4 pr-4 mt-4 bg-white rounded-lg border-2 border-solid border-zinc-300">
		<Image
			src={imageSrc}
			alt={title}
			className="shrink-0 w-20 aspect-[1.03] rounded-tl-md rounded-bl-md"
		/>
		<div className="flex flex-col flex-1 py-2 my-auto">
			<h3 className="text-base font-semibold text-black">{title}</h3>
			<time className="mt-1 text-xs leading-4 text-zinc-500">
				{formatTime(time)}
			</time>
		</div>
	</div>
);

export { Card };
