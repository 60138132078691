import { useState } from 'react';

interface ExpandableTextProps {
	text: string;
}

const ExpandableText = ({ text }: ExpandableTextProps): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState(false);
	const MAX_DISPLAY_LENGTH = 200;

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const displayText = isExpanded ? text : text.slice(0, MAX_DISPLAY_LENGTH);

	return (
		<div>
			<p>
				{displayText}
				{!isExpanded && text.length > MAX_DISPLAY_LENGTH && (
					<span
						className="cursor-pointer text-gray-600 ml-2"
						onClick={toggleExpand}
					>
						...see more
					</span>
				)}
			</p>
		</div>
	);
};

export { ExpandableText };
